<template>
  <div>
    <h4 class="pl-2 font-weight-bold">選手登録申請承認</h4>
    <!-- Filter Search -->
    <div class="top-event d-flex flex-column flex-md-column">
      <b-button
        class="d-block d-md-none mx-0 btn-toggle mb-2 mx-md-2"
        v-b-toggle.event-filter
        >絞り込む</b-button
      >
      <b-collapse id="event-filter" class="w-100 d-md-block">
        <b-row class="filter-search justify-content-end">
          <b-col cols="12" sm="6" md="3" lg="3">
            <b-input-group class="mb-1 mb-md-3">
              <b-form-input
                v-model="fromDate"
                type="text"
                placeholder="開始期間"
                class="text-truncate"
                disabled
                autocomplete="off"
              ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker
                  :hide-header="true"
                  label-help=""
                  v-model="fromDate"
                  locale="ja"
                  @input="fromDateSelected"
                  button-only
                  right
                >
                  <template #button-content>
                    <esports-calendar-date />
                  </template>
                </b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col cols="12" sm="6" md="3" lg="3">
            <b-input-group class="mb-1 mb-md-3">
              <b-form-input
                v-model="toDate"
                type="text"
                placeholder="終了期間"
                class="text-truncate"
                disabled
                autocomplete="off"
              ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker
                  :hide-header="true"
                  label-help=""
                  v-model="toDate"
                  locale="ja"
                  @input="toDateSelected"
                  button-only
                  right
                >
                  <template #button-content>
                    <esports-calendar-date />
                  </template>
                </b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col cols="12" sm="6" md="3" lg="3">
            <b-form-input
              class="name-event mb-2 mb-md-3 text-truncate"
              v-model="filterAll"
              debounce="500"
              placeholder="フリーワード"
            ></b-form-input>
          </b-col>
          <b-col cols="12" sm="6" md="3" lg="3">
            <div class="d-flex">
              <b-button
                class="primary mb-4 mb-md-3 mr-2"
                @click="handleFilterEvents"
                block
                >検索</b-button
              >
            </div>
          </b-col>
        </b-row>
      </b-collapse>
    </div>
    <!-- Main table element -->
    <b-table
      :items="filtered"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filterAll"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :show-empty="hideBeforeLoading"
      small
      stacked="md"
      @filtered="onFiltered"
      responsive="sm"
      bordered
      ref="table-data"
    >
      <template #top-row="row">
        <b-th v-for="(col, index) in row.fields" :key="index">
          <b-form-input
            v-model="filters[col.key]"
            v-if="col.key !== 'id'"
            type="search"
            :placeholder="col.label"
            debounce="500"
          ></b-form-input>
        </b-th>
      </template>

      <template #cell(name)="row">
        {{ row.item.name }}
      </template>

      <template #cell(teamsContent)="row">
        <h5>
          <b-badge
            class="mx-1"
            v-for="team in row.item.teams"
            :key="team.teamId"
            variant="warning"
            >{{ team.title }}</b-badge
          >
        </h5>
      </template>

      <template #cell(id)="row">
        <span
          role="button"
          class="mx-2 my-1 d-inline-block position-relative"
          @click="handleDetail(row)"
          ><b-badge variant="info" class="text-white py-2">{{ row.detailsShowing ? "詳細非表示" : "詳細表示" }}</b-badge>
          <esports-loading-button
            v-if="row.item.isLoading"
            width="30px"
            height="30px"
            outerBorder="#333"
            innerBorder="#eee"
          />
        </span>
        <span
          role="button"
          class="mx-2 my-1 d-inline-block position-relative"
          @click="handleSendRequest(row.item)"><b-badge variant="success" class="py-2">確認する</b-badge>
        </span>
        
      </template>

      <template #row-details="row">
        <b-card no-body class="overflow-hidden animate__animated" :class="[row.detailsShowing ? 'animate__zoomOut' : 'animate__zoomIn']">
          <b-row no-gutters>
            <b-col md="3" lg="4">
              <b-card-img-lazy
                :src="row.item.picture"
                alt="Image"
                class="rounded-0"
                v-bind="mainProps('#fff')"
              ></b-card-img-lazy>
              <label class="mt-1 ml-1" v-if="row.item.content">{{ row.item.content.labelFileName }}</label>
            </b-col>
            <b-col md="7" lg="7">
              <b-card-body>
                <h5>{{ row.item.name }}</h5>
                <b-card-text>
                  <b>チーム名: </b>
                  <h5>
                    <b-badge
                      class="mx-1"
                      v-for="team in row.item.teams"
                      :key="team.teamId"
                      variant="warning"
                      >{{ team.title }}</b-badge
                    >
                  </h5>
                </b-card-text>
              </b-card-body>
              <ul>
                <!-- <li><b>Player ID</b>: {{ row.item.playerId }}</li> -->
                <li><b>作成日</b>: {{ row.item.createdDate }}</li>
                <template v-if="row.item.content">
                  <li><b>出身地</b>: {{ row.item.content.hometown }}</li>
                  <li>
                    <b>実績</b>:
                    <b-badge
                      class="mx-1"
                      v-for="(archive, index) in row.item.content.achievement"
                      :key="index"
                      >{{ archive }}</b-badge
                    >
                  </li>
                  <li v-if="row.item.content.extensionInfos">
                    <b>追加項目</b>:
                    <template>
                      <p
                        class="mb-0"
                        v-for="(info, index) in Object.entries(
                          row.item.content.extensionInfos
                        )"
                        :key="index"
                      >
                        <template v-if="
                          info[0] === 'twitter' || 
                          info[0] === 'youtube' || 
                          info[0] === 'facebook' || 
                          info[0] === 'mildom' || 
                          info[0] === 'twitch' || 
                          info[0] === 'other'"
                        >
                          <b class="text-capitalize">{{ info[0] }}</b
                          >: <b-link :href="info[1]" target="_blank">{{ info[1] }}</b-link>
                        </template>
                        <template v-else>
                          <b>{{ info[0] }}</b
                          >: {{ info[1] }}
                        </template>
                      </p>
                    </template>
                  </li>
                </template>
              </ul>
            </b-col>
          </b-row>
        </b-card>
      </template>

      <template #empty>
        <div role="alert" aria-live="polite">
          <div class="text-center my-2 not-result">
            該当データが存在しません。
            <span role="button" @click="resetFilter" class="ext-reset"
              >リセット</span
            >
          </div>
        </div>
      </template>
      <template #emptyfiltered>
        <div role="alert" aria-live="polite">
          <div class="text-center my-2 not-result">
            該当データが存在しません。
            <span role="button" @click="resetFilter" class="ext-reset"
              >リセット</span
            >
          </div>
        </div>
      </template>
    </b-table>

    <esports-paging 
      :items="items"
      :limit="limit"
      :totalRows="totalRows"
      :isResult="isResult">
      <template v-slot:perpage>
        <b-form-select
          id="per-page-select"
          v-model="perPage"
          :options="pageOptions"
        ></b-form-select>
      </template>
      <template v-slot:paging>
        <template v-if="isResult && totalRows > perPage">
          <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          class="my-0 ml-3"
        ></b-pagination>
        </template>
      </template>
    </esports-paging>

    <!-- create/update Game Modal -->
    <b-modal
      :id="playerInfo.id"
      :title="playerInfo.title_modal"
      ref="player-info-modal"
      @hide="resetplayerInfo"
      @shown="handleUpdatePlayer"
    >
      <div class="form-modal">
        <transition-alert>
          <template v-if="msgErrors.length">
            <b-alert
              :show="dismissCountDown"
              dismissible
              @dismissed="dismissCountDown = 0"
              @dismiss-count-down="countDownChange"
              variant="danger"
            >
              <span
                class="d-block"
                v-for="(msg, index) in msgErrors"
                :key="index"
                >{{ msg }}</span
              >
            </b-alert>
          </template>
        </transition-alert>
        <transition-alert>
          <template v-if="msgSuccess">
            <b-alert
              :show="dismissCountDown"
              dismissible
              @dismissed="dismissCountDown = 0"
              @dismiss-count-down="countDownChange"
              variant="success"
            >
              {{ msgSuccess }}
            </b-alert>
          </template>
        </transition-alert>
        <b-form-group class="mb-2">
          <b-form-input
            v-model="playerInfo.title"
            type="text"
            placeholder="選手名"
            :state="ckStateGame('title')"
          ></b-form-input>
          <b-form-invalid-feedback>選手名は必須です。</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group class="mb-2">
          <b-form-input
            v-model="playerInfo.hometown"
            type="text"
            placeholder="出身地"
          ></b-form-input>
        </b-form-group>
        <b-form-group class="mb-2">
          <b-form-textarea
            placeholder="概要"
            v-model="playerInfo.description"
            rows="3"
            no-resize
          ></b-form-textarea>
          <b-form-invalid-feedback
            >概要は必須です。</b-form-invalid-feedback
          >
        </b-form-group>
        <b-form-group class="mb-2">
          <label>実績</label>
          <b-form-tags
            placeholder="エンターキーで追加"
            v-model="playerInfo.achievement"
            addButtonText="追加"
            duplicateTagText="重複タグ:"
          ></b-form-tags>
        </b-form-group>
        <b-form-group class="mb-2">
          <label
            >追加項目
            <span
              role="button"
              @click="addAttribute"
              v-if="playerInfo.extensionInfos.length < MAX_ATTRIBUTES"
              ><b-badge variant="success"
                >追加
                <i
                  class="fa fa-plus-circle"
                  aria-hidden="true"
                ></i></b-badge></span
          ></label>
          <transition-group 
            enter-active-class="animate__animated animate__slideInUp" 
            leave-active-class="animate__animated animate__fadeOutDown"
          >
          <div
            class="d-flex align-items-center position-relative"
            v-for="(item, index) in playerInfo.extensionInfos"
            :key="`key-${index}`"
          >
            <b-row>
              <b-col>
                <b-form-group class="mb-2">
                  <b-form-input
                    type="text"
                    placeholder="項目名"
                    v-model="item.key"
                    :state="ckStateExtentInfo(index, 'key')"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    >項目名は必須です。</b-form-invalid-feedback
                  >
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group class="mb-2">
                  <b-form-input
                    type="text"
                    placeholder="値"
                    v-model="item.value"
                    :state="ckStateExtentInfo(index, 'value')"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    >値は必須です。</b-form-invalid-feedback
                  >
                </b-form-group>
              </b-col>
            </b-row>
            <div
              class="pl-2 btn-remove-attribute"
              @click="removeAttribute(index)"
            >
              <i
                class="fa fa-times text-danger"
                aria-hidden="true"
                role="button"
              ></i>
            </div>
          </div>
          </transition-group>
        </b-form-group>
        <b-form-group class="position-relative">
          <b-form-file
            v-model="playerInfo.file"
            placeholder="画像をアップロード"
            :state="playerId ? '' : ckStateGame('file')"
            drop-placeholder="画像をアップロード"
            class="hide-banner"
            browse-text="ファイルを選択"
            accept="image/*"
          ></b-form-file>
          <label class="custom-file-label filename">{{ filename }}</label>
          <b-form-invalid-feedback>画像アップロードは必須です。</b-form-invalid-feedback>
        </b-form-group>
      </div>
      <template #modal-footer="{ close }">
        <b-button
          class="primary"
          type="submit"
          :disabled="isSubmitted"
          @click="onSubmitPlayer(close)"
          >{{ playerId ? "更新" : "登録" }}
          <esports-loading-button
            v-if="isSubmitted"
            width="30px"
            height="30px"
            outerBorder="#333"
            innerBorder="#eee"
        /></b-button>
      </template>
    </b-modal>
    <!-- delete Game Modal -->
    <b-modal id="deleteEvent" hide-header header-class="text-danger" centered>
      <div class="form-data">
        <h5 class="text-center py-3">
          この選手を完全に削除してもよろしいでしょうか？
        </h5>
        <transition-alert>
          <template v-if="msgSuccess">
            <b-alert
              :show="dismissCountDown"
              dismissible
              @dismissed="dismissCountDown = 0"
              @dismiss-count-down="countDownChange"
              variant="success"
            >
              {{ msgSuccess }}
            </b-alert>
          </template>
        </transition-alert>
      </div>
      <template #modal-footer="{ close, cancel }">
        <b-button @click="cancel">キャンセル</b-button>
        <b-button
          variant="primary"
          type="submit"
          :disabled="isSubmitted"
          @click="handleDeleteEvent(close)"
          >OK
          <esports-loading-button
            v-if="isSubmitted"
            width="30px"
            height="30px"
            outerBorder="#333"
            innerBorder="#eee"
        /></b-button>
      </template>
    </b-modal>

     <!-- send request Player Modal -->
     <b-modal id="sendRequestPlayerModal" 
      hide-header 
      header-class="text-danger" centered
      @hide="resetSendRequest"
      >
      <div class="form-data">
        <div>
          <b-form-group v-slot="{ ariaDescribedby }" class="m-3" label="こちら申請を承認または否認してください。">
            <b-form-radio-group
              v-model="selected_request"
              :options="options_request"
              :aria-describedby="ariaDescribedby"
              name="send-request"
              stacked
            ></b-form-radio-group>
          </b-form-group>
          <b-form-group class="mb-2" v-if="selected_request === 'reject'">
            <b-form-textarea
              placeholder="メッセージ"
              v-model="message_request"
              rows="3"
              no-resize
            ></b-form-textarea>
          </b-form-group>
        </div>
        <transition-alert>
          <template v-if="msgErrors.length">
            <b-alert
              :show="dismissCountDown"
              dismissible
              @dismissed="dismissCountDown = 0"
              @dismiss-count-down="countDownChange"
              variant="danger"
            >
              <span
                class="d-block"
                v-for="(msg, index) in msgErrors"
                :key="index"
                >{{ msg }}</span
              >
            </b-alert>
          </template>
        </transition-alert>
        <transition-alert>
          <template v-if="msgSuccess">
            <b-alert
              :show="dismissCountDown"
              dismissible
              @dismissed="dismissCountDown = 0"
              @dismiss-count-down="countDownChange"
              variant="success"
            >
              {{ msgSuccess }}
            </b-alert>
          </template>
        </transition-alert>
      </div>
      <template #modal-footer="{ cancel, close }">
        <b-button @click="cancel">キャンセル</b-button>
        <b-button
          variant="primary"
          type="submit"
          :disabled="isSubmitted"
          @click="handleSubmitRequest(close)"
          >確認する
          <esports-loading-button
            v-if="isSubmitted"
            width="30px"
            height="30px"
            outerBorder="#333"
            innerBorder="#eee"
        /></b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { dismissCount } from "@/mixins";
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { EventBus } from '@/utils/event-bus';

export default {
  name: "SendRequest",
  data() {
    return {
      isSubmitted: false,
      fromDate: "",
      toDate: "",
      playerId: "",
      version: 0,
      items: [],
      fields: [
        {
          key: "name",
          label: "選手名",
          sortable: true,
          sortDirection: "desc",
        },
        { key: "teamsContent", label: "チーム名", sortDirection: "desc" },
        {
          key: "createdDate",
          label: "作成日",
          sortable: true,
          sortDirection: "desc",
        },
        { key: "id", label: "" },
      ],
      filters: {
        name: "",
        teamsContent: "",
        createdDate: "",
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [10, 15, 20, { value: 100, text: "すべて" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filterAll: null,
      filterOn: ["name", "teams", "createdDate"],
      playerInfo: {
        id: "info-modal",
        file: null,
        title_modal: "",
        title: "",
        description: "",
        extensionInfos: [],
        hometown: "",
        achievement: [],
      },
      playerTemp: null,
      MAX_ATTRIBUTES: 5,
      msgErrors: [],
      msgSuccess: null,
      search_name_game: "",
      options_name: [],
      action_type: "",
      isEnableCloseModal: false,
      filename: "",
      limit: 0,
      isDetailRow: false,
      selected_request: 'approve',
      options_request: [
        { text: '承認', value: 'approve' },
        { text: '否認', value: 'reject' },
      ],
      message_request: ''
    };
  },
  mixins: [validationMixin, dismissCount],
  validations() {
    if (this.playerId) {
      return {
        playerInfo: {
          title: { required },
          extensionInfos: {
            $each: {
              key: { required },
              value: { required },
            },
          },
        },
      };
    }
    return {
      playerInfo: {
        title: { required },
        file: { required },
        extensionInfos: {
          $each: {
            key: { required },
            value: { required },
          },
        },
      },
    };
  },
  watch: {
    perPage() {
      this.$nextTick(() => {
        this.currentPage = 1;
        this.limit = this.$refs["table-data"]?.getTbodyTrs().length ?? 0;
      });
    },
    currentPage(page) {
      this.$nextTick(() => {
        let currentItems = this.$refs["table-data"]?.getTbodyTrs().length ?? 0;
        this.limit = this.perPage * (page - 1) + currentItems;
      });
    },
    msgErrors(nVal) {
      if (nVal) {
        this.dismissCountDown = this.dismissSecs;
      }
    },
    msgSuccess(nVal) {
      if (nVal) {
        this.dismissCountDown = this.dismissSecs;
      }
    },
    filtered() {
      this.$nextTick(() => {
        this.currentPage = this.isDetailRow ? this.currentPage : 1;
        this.limit = this.$refs["table-data"]?.getTbodyTrs().length ?? 0;
      });
    },
    "playerInfo.file": {
      deep: true,
      immediate: true,
      handler(nVal) {
        if (nVal) {
          this.filename = nVal.name;
        } else {
          this.filename = "画像をアップロード";
        }
      },
    },
  },
  computed: {
    isValidGame() {
      return !this.$v.playerInfo.$anyError;
    },
    isResult() {
      return this.items.length;
    },
    filtered() {
      let filtered = this.items.filter((item) => {
        return Object.keys(this.filters).every((key) =>
          String(item[key])
            ?.toLowerCase()
            .includes(this.filters[key]?.toLowerCase())
        );
      });
      return filtered.length > 0 ? filtered : [];
    },
  },
  async mounted() {
    this.setLazyLoading(true);
    await Promise.all([this.getEventsAll()]);
    this.hideBeforeLoading = true;

    this.playerTemp = { ...this.playerInfo };
    this.resetItemArraysPlayerTemp();

    if (this.items.length > this.perPage) {
      this.limit = this.perPage;
    } else {
      this.limit = this.items.length;
    }
  },
  methods: {
    resetItemArraysPlayerTemp() {
      if (this.playerTemp.extensionInfos) delete this.playerTemp.extensionInfos;
      if (this.playerTemp.achievement) delete this.playerTemp.achievement;

      this.playerTemp.extensionInfos = cloneDeep(
        this.playerInfo.extensionInfos
      );
      this.playerTemp.achievement = cloneDeep(this.playerInfo.achievement);
    },
    handleUpdatePlayer() {
      this.playerTemp = { ...this.playerInfo };
      this.resetItemArraysPlayerTemp();
    },
    showModalConfirmClosePlayer() {
      this.$bvModal
        .msgBoxConfirm("本当にキャンセルしてもよろしいでしょうか？", {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: "OK",
          cancelTitle: "キャンセル",
          footerClass: "p-2 footer-mgs",
          hideHeaderClose: false,
          hideHeader: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.isEnableCloseModal = true;
            this.$refs["player-info-modal"].hide();
          }
        });
    },
    async getEventsAll(params) {
      const result = await this.$store
        .dispatch("register_player/getRegisterPlayersAll", params)
        .catch((err) => {
          this.setLazyLoading(false);
          this.catchErrorNetwork(err);
        });

      if (result) {
        this.items = result.map((player, index) => {
          player.picture = `${process.env.VUE_APP_BACKEND + player.picture}`;

          if (player.createdDate) {
            player.createdDate = moment(player.createdDate).format(
              "YYYY-MM-DD"
            );
          }

          if (player.teams?.length) {
            player.teams = player.teams.map((team) => {
              team.description = null;
              return team;
            });

            player.teamsContent = player.teams.reduce(
              (result, next) => result + " " + next.title,
              ""
            );
          }

          player.isLoading = false;

          return player;
        });
        this.totalRows = this.items.length;
        this.setLazyLoading(false);
        EventBus.$emit('loadNumberRequests', this.totalRows);
      }
    },
    async handleFilterEvents() {
      let params = {
        fromDate: this.fromDate,
        toDate: this.toDate,
      };

      if (!params.fromDate) delete params.fromDate;
      if (!params.toDate) delete params.toDate;

      await this.getEventsAll(params);
    },
    async onSubmitPlayer(close) {
      if (this.validate()) {
        let params = {
          file: this.playerInfo.file,
          playerInfo: "",
          playerId: this.playerId,
        };

        let extensionInfos = {};
        this.playerInfo.extensionInfos.forEach((item) => {
          extensionInfos[item.key] = item.value;
        });

        if(isEmpty(extensionInfos)) extensionInfos = null;

        let playerInfo = {
          name: this.playerInfo.title,
          description: this.playerInfo.description,
          hometown: this.playerInfo.hometown,
          achievement: this.playerInfo.achievement,
          extensionInfos,
        };

        if (this.playerId) {
          playerInfo.playerId = this.playerId;
        }

        params.playerInfo = JSON.stringify(playerInfo);

        this.showMsgConfirmCreate(() => {
          this.isEnableCloseModal = true;
          this.upsertPlayer(params, close);
        });
      }
    },
    async upsertPlayer(params, close) {
      this.isSubmitted = true;

      const result = await this.$store
        .dispatch("adminPlayer/upSertPlayer", params)
        .catch((err) => {
          this.isSubmitted = false;

          if (err?.errors) {
            this.msgErrors = Object.values(err.errors);
          } else {
            this.msgErrors = [err];
          }
        });

      if (result) {
        this.reloadListPlayer(result, close);
      }
    },
    async approvePlayer(params, close) {
      this.isSubmitted = true;

      const result = await this.$store
        .dispatch("register_player/approvePlayer", params)
        .catch((err) => {
          this.isSubmitted = false;

          if (err?.errors) {
            this.msgErrors = Object.values(err.errors);
          } else {
            this.msgErrors = [err];
          }
        });

      if (result) {
        this.reloadListPlayer(result, close);
      }
    },
    async rejectPlayer(params, close) {
      this.isSubmitted = true;

      const result = await this.$store
        .dispatch("register_player/rejectPlayer", params)
        .catch((err) => {
          this.isSubmitted = false;

          if (err?.errors) {
            this.msgErrors = Object.values(err.errors);
          } else {
            this.msgErrors = [err];
          }
        });

      if (result) {
        this.reloadListPlayer(result, close);
      }
    },
    showMsgConfirmCreate(cb) {
      let msg = this.playerId ? 'この選手を更新してもよろしいですか？' : 'この選手を登録してもよろしいですか？';
      this.$bvModal
        .msgBoxConfirm(msg, {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: "OK",
          cancelTitle: "キャンセル",
          footerClass: "p-2",
          hideHeaderClose: false,
          hideHeader: true,
          centered: true,
        })
        .then((value) => {
          if (value === null) return;

          if (value) {
            if (cb) cb();
            return;
          }

          this.isEnableCloseModal = true;
          this.$refs["player-info-modal"].hide();
        });
    },
    async infoModal(item) {
      if (!item) {
        this.playerId = "";
        this.playerInfo.title_modal = `新規作成`;
        this.playerInfo.id = "info-modal";
        this.playerInfo.playerId = "";

        this.playerTemp.title_modal = `新規作成`;
        this.playerTemp.id = "info-modal";
        this.playerTemp.playerId = "";

        this.filename = '画像をアップロード';

        this.$root.$emit("bv::show::modal", this.playerInfo.id);
        return;
      }
      this.playerInfo.title_modal = `更新`;
      this.playerInfo.playerId = item.playerTempId;

      const result = await this.$store
        .dispatch("register_player/getDetaiPlayerByAdmin", item.playerTempId)
        .catch((err) => {
          console.log(err);
        });

      if (result) {
        this.loadDataInfo(item, result);
        this.$root.$emit("bv::show::modal", this.playerInfo.id);
      }
    },
    async handleDeleteEvent(close) {
      this.isSubmitted = true;

      const result = await this.$store
        .dispatch("adminPlayer/deletePlayer", this.playerId)
        .catch((err) => {
          this.catchErrorNetwork(err);
        });

      if (result) {
        this.reloadListPlayer(result, close);
      }
    },
    async resetFilter() {
      this.filterAll = "";
      this.filters.name = "";
      this.filters.teamsContent = "";
      this.filters.createdDate = "";
      this.fromDate = null;
      this.toDate = null;
      await this.handleFilterEvents();
    },
    async reloadListPlayer(result, close) {
      this.msgSuccess = result.message;
      this.msgErrors = [];
      this.isSubmitted = false;
      this.resetData();
      this.handleSuccessResp(close);
      await this.handleFilterEvents();
    },
    fromDateSelected(date) {
      if (!this.toDate || moment(date).isSameOrAfter(this.toDate, "day")) {
        this.toDate = date;
      }
    },
    toDateSelected(date) {
      if (!this.fromDate || !moment(date).isSameOrAfter(this.fromDate, "day")) {
        this.fromDate = date;
      }
    },
    validate() {
      this.$v.$touch();
      return this.isValidGame;
    },
    ckStateGame(val) {
      let field = this.$v.playerInfo[val];
      return !field.$dirty || !field.$invalid;
    },
    ckStateExtentInfo(index, val) {
      let infos = this.$v.playerInfo.extensionInfos;
      return (
        !infos.$each[index][val].$dirty || !infos.$each[index][val].$invalid
      );
    },
    resetData() {
      this.page = 1;
      this.playerId = "";
      this.fromDate = "";
      this.toDate = "";
      this.perPage = 20;
    },
    deleteModal(item) {
      this.playerId = item.playerTempId;
      this.$root.$emit("bv::show::modal", "deleteEvent");
    },
    resetplayerInfo(data) {
      if (
        !isEqual(this.playerInfo, this.playerTemp) &&
        !this.isEnableCloseModal
      ) {
        this.showModalConfirmClosePlayer();
        data.preventDefault();
        return;
      }

      this.playerInfo.title_modal = "";
      this.playerInfo.file = null;
      this.playerInfo.title = "";
      this.playerInfo.description = "";
      this.playerInfo.hometown = "";
      this.playerInfo.achievement = [];
      this.playerInfo.extensionInfos = [];
      this.action_type = "";

      this.isEnableCloseModal = false;

      this.$v.$reset();
    },
    onFiltered() {
      this.$nextTick(() => {
        this.currentPage = 1;
        this.limit = this.$refs["table-data"]?.getTbodyTrs().length ?? 0;
      });
    },
    addAttribute() {
      if (this.playerInfo.extensionInfos.length > this.MAX_ATTRIBUTES) return;
      this.playerInfo.extensionInfos.push({ key: "", value: "" });
    },
    removeAttribute(index) {
      this.playerInfo.extensionInfos.splice(index, 1);
    },
    async handleDetail(row) {
      row.item.isLoading = true;
      this.isDetailRow = true;

      let currentIdx = this.items.findIndex(
        (game) => game.playerTempId === row.item.playerTempId
      );

      if (row.detailsShowing) {
        row.item.isLoading = false;
        this.isDetailRow = false;
        row.toggleDetails();
        return;
      }

      const result = await this.$store
        .dispatch("register_player/getDetaiPlayerByAdmin", row.item.playerTempId)
        .catch((err) => {
          console.log(err);
        });

      if (result) {
        let content = result;
        content.picture = `${process.env.VUE_APP_BACKEND + content.picture}`;
        content.extensionInfos = JSON.parse(content.extensionInfos);

        content.labelFileName = this.getNamePicture(result.picture);

        if (currentIdx > -1) {
          this.items[currentIdx].content = content;
        }

        row.toggleDetails();
        row.item.isLoading = false;
      }
    },
    handleSuccessResp(close) {
      setTimeout(() => {
        close();
        this.msgSuccess = null;
        this.dismissCountDown = 0;
      }, 2000);
    },
    loadDataInfo(item, result) {
      this.playerInfo.playerId = "";
      this.playerInfo.title = result.name;
      this.playerId = item.playerTempId;
      this.playerInfo.description = result.description;
      this.playerInfo.hometown = result.hometown;
      this.playerInfo.achievement = result.achievement;

      let extendsInfo = result.extensionInfos
        ? JSON.parse(result.extensionInfos)
        : [{ key: "", value: "" }];

      if (!Array.isArray(extendsInfo)) {
        this.playerInfo.extensionInfos = [];

        Object.entries(extendsInfo).forEach((item) => {
          this.playerInfo.extensionInfos.push({
            key: item[0],
            value: item[1],
          });
        });
      }

      this.filename = this.getNamePicture(result.picture);
    },
    handleSendRequest(item) {
      this.playerId = item.playerTempId;
      this.version = item.version;
      this.$root.$emit("bv::show::modal", "sendRequestPlayerModal");
    },
    resetSendRequest() {
      this.message_request = '';
      this.selected_request = 'approve';
      this.playerId = "";
      this.version = 0;
    },
    handleSubmitRequest(close) {
      this.showMsgConfirmSendRequest(() => {
        if (this.selected_request === 'approve') {
          this.approvePlayer({ playerTempId: this.playerId, version: this.version }, close);
        } else {
          this.rejectPlayer({ playerTempId: this.playerId, version: this.version, reason: this.message_request }, close);
        } 
      });
    },
    showMsgConfirmSendRequest(cb) {
      let msg = this.selected_request === 'approve' ? '承認してよろしいでしょうか?' : '否認してよろしいでしょうか?';
      this.$bvModal
        .msgBoxConfirm(msg, {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: "OK",
          cancelTitle: "キャンセル",
          footerClass: "p-2",
          hideHeaderClose: false,
          hideHeader: true,
          centered: true,
        })
        .then((value) => {
          if (value === null) return;

          if (value) {
            if (cb) cb();
            return;
          }
        });
    },
  },
};
</script>